import React from "react";
import { Helmet } from "react-helmet";
import { Button } from "@material-ui/core";
import { WhatsApp } from "@material-ui/icons";
import { TextField, Select, FormControl, InputLabel, MenuItem } from "@material-ui/core";

import Header from "../sections/Header";
import LineSeparator from "../sections/LineSeparator";
import GreenBlockSection from "../sections/GreenBlockSection";

const styles = {
  homeButton: {
    width: "100%",
    maxWidth: "300px",
    height: "50px",
    textTransform: "none",
  },
  input: {
    marginBottom: 15,
    textTransform: "none",
    textAlign: "left",
  },
  submitButton: {
    width: 220,
    backgroundColor: "#fff",
    color: "#6F766B",
    marginTop: 10,
    textTransform: "none",
  },
};

const TICKETS = {
  THU_DAY: {
    name: "Shepstone Christmas Market 2024 Thursday pass",
    label: "Thursday pass",
    price: 120,
  },
  FRI_DAY: {
    name: "Shepstone Christmas Market 2024 Friday pass",
    label: "Friday pass",
    price: 120,
  },
  TWO_DAY: {
    name: "Shepstone Christmas Market 2024 Two-day pass",
    label: "Two-day pass",
    price: 200,
  },
};


const MarketTickets = () => {
  const [type, setType] = React.useState(TICKETS.THU_DAY);
  const [quantity, setQuantity] = React.useState(1);
  const [purchaserName, setName] = React.useState("");
  const [purchaserEmail, setEmail] = React.useState("");
  const [purchaserCell, setCell] = React.useState("");

  return (
    <div>
      <Helmet>
        <title>Christmas Market 2024 | The Shepstone Experience</title>
        <meta
          name={"description"}
          content={"Book your tickets for the magical Christmas Market at Shepstone Gardens in Johannesburg. Tickets are limited, so book soon!"}
        ></meta>
      </Helmet> 

      <Header
        bgImg={"/images/market/vendor-header1.jpg"}
        headText={"CHRISTMAS MARKET TICKETS"}
      />

      <LineSeparator noLine />
      <LineSeparator noLine />

      <section className={"market-intro-section"}>
        <GreenBlockSection
          headText={"Christmas Market 2024"}
          subHeadText={"The Shepstone Experience"}
        >
          <div className={"three-columns"}>
            <p className={"venue-intro-text"}>
              Join us for a magical weekend of Christmas spirit and joyous celebrating at Shepstone Gardens.
            </p>
            <p className={"venue-intro-text"}>
              Shop for unique gifts, enjoy delicious food and drinks, and experience the magic of Christmas.
            </p>
            <p className={"venue-intro-text"}>
              Tickets are limited, so book soon! Options include a one-day pass for either day, or a full two-day pass.
            </p>
          </div>
        </GreenBlockSection>
        <LineSeparator noLine />
        <LineSeparator noLine />

        <GreenBlockSection
          headText={"Details"}
          subHeadText={"Event"}
        >
          <img
            src={"/images//market/tickets.jpg"}
            className={"banner-image"}
          />
          <div className={"market-details-block"}>
            <p className={"market-sub-head"}>Dates & Times</p>
            <p className={"market-details"}>Thursday, 14 November 2024 from 10:00 - 20:00</p>
            <p className={"market-details"}>Friday, 15 November 2024 from 10:00 - 22:00</p>
          </div>
          <div className={"market-details-block"}>
            <p className={"market-sub-head"}>Ticket Prices</p>
            <p className={"market-details"}>One-day pass: R120</p>
            <p className={"market-details"}>Two-day pass: R200</p>
          </div>
        </GreenBlockSection>
      </section>

      <form
        className={"message-form standard-width"}
        name={"PayFastPayNowForm"}
        action={"https://www.payfast.co.za/eng/process"}
        method={"post"}
      >
        <h3>Book your ticket!</h3>
        <div className={"message-form-inner"}>
          {/* HIDDEN PAYFAST FIELDS */}
          <TextField
            required
            type={"hidden"}
            name={"cmd"}
            value={"_paynow"}
          />
          <TextField
            required
            type={"hidden"}
            name={"receiver"}
            pattern={"[0-9]"}
            value={"15664450"}
          />
          <TextField
            type={"hidden"}
            name={"return_url"}
            value={"https://www.shepstonegardens.co.za/thank-you"}
          />
          <TextField
            type={"hidden"}
            name={"cancel_url"}
            value={"https://www.shepstonegardens.co.za/Christmas-Market-Tickets"}
          />
          <TextField
            type={"hidden"}
            name={"notify_url"}
            value={"https://www.shepstonegardens.co.za/api/Contact/NotifyMarket"}
          />
          <TextField
            required
            type={"hidden"}
            name={"amount"}
            value={type.price*quantity}
          />

          {/* VISIBLE FIELDS */}
          <FormControl
            style={styles.input}
            variant={"outlined"}
            fullWidth
          >
            <InputLabel>Ticket</InputLabel>
            <Select
              value={type.label}
              onChange={(e) => {
                if (e.target.value === TICKETS.THU_DAY.label) {
                  setType(TICKETS.THU_DAY);
                }
                else if (e.target.value === TICKETS.FRI_DAY.label) {
                  setType(TICKETS.FRI_DAY);
                }
                else if (e.target.value === TICKETS.TWO_DAY.label) {
                  setType(TICKETS.TWO_DAY);
                }
              }}
              MenuProps={{
                disableScrollLock: true,
              }}
              label={"Ticket"}
            >
              <MenuItem value={TICKETS.THU_DAY.label}>
                {TICKETS.THU_DAY.label}
              </MenuItem>
              <MenuItem value={TICKETS.FRI_DAY.label}>
                {TICKETS.FRI_DAY.label}
              </MenuItem>
              <MenuItem value={TICKETS.TWO_DAY.label}>
                {TICKETS.TWO_DAY.label}
              </MenuItem>
            </Select>
          </FormControl>

          <TextField
            style={styles.input}
            variant={"outlined"}
            label={"Quantity"}
            fullWidth
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            type={"number"}
            name={"custom_quantity"}
            min={"1"}
            required
          />

          <TextField
            style={styles.input}
            variant={"outlined"}
            label={"Your name"}
            fullWidth
            value={purchaserName}
            onChange={(e) => setName(e.target.value)}
            name={"custom_str1"}
            required
          />

          <TextField
            style={styles.input}
            variant={"outlined"}
            label={"Your email"}
            fullWidth
            value={purchaserEmail}
            onChange={(e) => setEmail(e.target.value)}
            type={"email"}
            name={"email_address"}
            required
          />

          <TextField
            style={styles.input}
            variant={"outlined"}
            label={"Your contact number"}
            fullWidth
            value={purchaserCell}
            onChange={(e) => setCell(e.target.value)}
            type={"tel"}
            name={"custom_cell"}
            required
          />

          {/* HIDDEN PAYFAST FIELDS */}
          <TextField
            type={"hidden"}
            name={"item_name"}
            maxLength={"255"}
            value={`${type.name} (Quantity: ${quantity})`}
          />
          <TextField
            type={"hidden"}
            name={"item_description"}
            maxLength={"255"}
            value={`Purchaser details: ${purchaserName}, ${purchaserCell}, ${purchaserEmail}`}
          />

          <Button
            variant={"contained"}
            style={styles.submitButton}
            color={"primary"}
            type={"submit"}
          >
            Continue to payment
          </Button>
        </div>
      </form>

      <LineSeparator noLine />
      <p className={"text-404"}>If you'd like to chat with us, feel free to send us a WhatsApp and we'll help you however we can!</p>
      <Button
        href={"https://wa.me/27678170890"}
        target={"_blank"}
        startIcon={<WhatsApp />}
        variant={"contained"}
        color={"secondary"}
        style={styles.contactButton}
      >
        Start chat
      </Button>
      
      <LineSeparator noLine />
    </div>
  );
};

export default MarketTickets;

