
export const PAGE_ROUTES = {
  HOME: "/",
  HOMECORPORATE: "/Corporate-Venue",
  HOMEBDIAMOND: "/Your-Time-Is-Now",
  HOMELOVEISLOVE: "/Love-Is-Love",
  HOMEBANNERADS: "/Your-Venue",
  ABOUT: "/About",
  BLOG: "/Blog",
  BLOG_POST: "/Blog-post/:postID",
  FLORALS: "/Florals",
  GALLERY: "/Gallery",
  CONTACT: "/Contact",
  VENUES: "/Venues",
  VENUES_SHEP: "/Venues/Shepstone-Gardens",
  VENUES_HALL: "/Venues/The-Great-Hall",
  SHOP: "/Shop",
  THANK_YOU: "/Thank-You",
  MARKET_VENDOR: "/Christmas-Market-Vendor",
  MARKET_TICKETS: "/Christmas-Market-Tickets",
};

export const LOADING_STATE = {
  NOT_LOADING: 0,
  LOADING: 1,
  LOADED: 2,
  ERROR: 3,
};

export const ENTRY_POINT = {
  DEFAULT: 0,
  CORPORATE: 1,
  BLACK_DIAMOND: 2,
  LOVE_IS_LOVE: 3,
  BANNER_ADS: 4,
};