import "./topNav.css";

import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Button, IconButton, Drawer, Collapse, Menu, MenuItem } from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp, Menu as MenuIcon, Close } from "@material-ui/icons";

import { PAGE_ROUTES } from "../../utils/stateTypes";


// Material UI styles
const styles = {
  menuButton: {
    height: "100%",
    width: "100%",
    position: "relative",
    textTransform: "none",
  },
  menuFeaturedButton: {
    padding: "0 15px",
    zIndex: 1,
    textTransform: "none",
  },
  drawerButton: {
    width: "100%",
    color: "#fff",
    textTransform: "none",
    paddingTop: "15px",
    paddingBottom: "15px",
  },
  drawerFeaturedButton: {
    width: "80%",
    maxWidth: 200,
    color: "#fff",
    padding: "10px 0",
    marginTop: 5,
    backgroundColor: "#838b7f",
    textTransform: "none",
  },
  drawerSubButton: {
    width: "100%",
    color: "#fff",
    textTransform: "none",
    paddingTop: "10px",
    paddingBottom: "10px",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
  drawerClose: {
    position: "absolute",
    top: 10,
    right: 10,
    color: "#fff",
    textTransform: "none",
  },
  viewVenue: {
    textTransform: "none",
  },
  dropdownButton: {
    color: "#6F766B",
    fontSize: "0.875rem",
    padding: "10px 20px",
  },
};


const TopNav = () => {
  const location = useLocation();
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [showMobileDropdown, setShowMobileDropdown] = React.useState(false);
  const closeDrawer = React.useCallback(() => {
    setShowDrawer(false);
    setShowMobileDropdown(false);
    setMobileSub(false);
  }, []);
  const openDrawer = React.useCallback(() => setShowDrawer(true), []);

  let selectIndicatorLeft = 0;
  switch (location.pathname) {
    case PAGE_ROUTES.HOME:
      selectIndicatorLeft = 0;
      break;
    case PAGE_ROUTES.ABOUT:
      selectIndicatorLeft = 1;
      break;
    case PAGE_ROUTES.MARKET_VENDOR:
      selectIndicatorLeft = 2;
      break;
    case PAGE_ROUTES.MARKET_TICKETS:
      selectIndicatorLeft = 2;
      break;
    case PAGE_ROUTES.VENUES:
      selectIndicatorLeft = 3;
      break;
    case PAGE_ROUTES.VENUES_SHEP:
      selectIndicatorLeft = 3;
      break;
    case PAGE_ROUTES.VENUES_HALL:
      selectIndicatorLeft = 3;
      break;
    case PAGE_ROUTES.FLORALS:
      selectIndicatorLeft = 4;
      break;
    case PAGE_ROUTES.BLOG:
      selectIndicatorLeft = 5;
      break;
    case PAGE_ROUTES.GALLERY:
      selectIndicatorLeft = 6;
      break;
    case PAGE_ROUTES.CONTACT:
      selectIndicatorLeft = 7;
      break;
    default:
      break;
  }
  //Blog post exception
  if (location.pathname.includes(PAGE_ROUTES.BLOG_POST.replace(":postID", ""))) {
    selectIndicatorLeft = 5;
  }

  //Market dropdown
  const [subAnchor, setSubAnchor] = React.useState(null);
  const openSub = React.useCallback((e) => {
    setSubAnchor(e.currentTarget);
  }
  , []);
  const closeSub = React.useCallback(() => {
    setSubAnchor(null);
  }, []);

  
  // Mobile submenu
  const [mobileSub, setMobileSub] = React.useState(false);
  const toggleMobileSub = React.useCallback(() => {
    setMobileSub(!mobileSub);
  }, [mobileSub]);


  return (
    <nav className={"menu-top"}>
      <Link
        to={PAGE_ROUTES.HOME}
        className={"menu-top-logo"}
      >
        <img
          src={"/images/TSE-Logo2.png"}
          className={"menu-top-logo"}
        />
      </Link>

      <div className={"menu-top-buttons"}>
        <div
          className={"page-selected-cont"}
          style={{ transform: `translateX(${100 * selectIndicatorLeft}%)` }}
        >
          <div
            className={"page-selected"}
          />
        </div>
        <Link
          to={PAGE_ROUTES.HOME}
          className={"menu-top-button"}
        >
          <Button
            variant={"text"}
            color={"primary"}
            style={styles.menuButton}
          >
            Home
          </Button>
        </Link>
        <Link
          to={PAGE_ROUTES.ABOUT}
          className={"menu-top-button"}
        >
          <Button
            variant={"text"}
            color={"primary"}
            style={styles.menuButton}
          >
            About
          </Button>
        </Link>
        <span className={"menu-top-button"} >
          <Button
            variant={"text"}
            color={"primary"}
            style={styles.menuButton}
            onClick={openSub}
          >
            Christmas<ArrowDropDown style={{ marginLeft: -2 }} />
          </Button>
        </span>
        {/* Set up the market submenu */}
        <Menu
          keepMounted
          anchorEl={subAnchor}
          open={Boolean(subAnchor)}
          onClose={closeSub}
          disableScrollLock={true}
        >
          <Link to={PAGE_ROUTES.MARKET_TICKETS}>
            <MenuItem
              variant={"text"}
              style={styles.dropdownButton}
              onClick={closeSub}
            >
              Christmas Market Tickets
            </MenuItem>
          </Link>
          <Link to={PAGE_ROUTES.MARKET_VENDOR}>
            <MenuItem
              variant={"text"}
              style={styles.dropdownButton}
              onClick={closeSub}
            >
              Become a vendor
            </MenuItem>
          </Link>
        </Menu>
        <span
          onMouseEnter={() => setShowDropdown(true)}
          onMouseLeave={() => setShowDropdown(false)}
        >
          <Link
            to={PAGE_ROUTES.VENUES}
            className={"menu-top-button"}
          >
            <Button
              variant={"text"}
              color={"primary"}
              style={styles.menuButton}
              onClick={() => setShowDropdown(false)}
            >
              Venues<ArrowDropDown style={{ marginLeft: -2 }} />
            </Button>
          </Link>
          <div className={["nav-venues-dropdown", (showDropdown) ? "active" : ""].join(" ")}>
            <div className={"nav-venue-item"}>
              <h3>Shepstone Gardens</h3>
              <img src={"/images/venue-picker-shepstone.jpg"} />
              <Link to={PAGE_ROUTES.VENUES_SHEP}>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  onClick={() => setShowDropdown(false)}
                  style={styles.viewVenue}
                >
                  View venue
                </Button>
              </Link>
            </div>
            <div className={"nav-venue-item"}>
              <h3>The Great Hall</h3>
              <img src={"/images/venue-picker-great-hall.jpg"} />
              <Link to={PAGE_ROUTES.VENUES_HALL}>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  onClick={() => setShowDropdown(false)}
                  style={styles.viewVenue}
                >
                  View venue
                </Button>
              </Link>
            </div>
          </div>
        </span>
        <Link
          to={PAGE_ROUTES.FLORALS}
          className={"menu-top-button"}
        >
          <Button
            variant={"text"}
            color={"primary"}
            style={styles.menuButton}
          >
            Florals
          </Button>
        </Link>
        <Link
          to={PAGE_ROUTES.BLOG}
          className={"menu-top-button"}
        >
          <Button
            variant={"text"}
            color={"primary"}
            style={styles.menuButton}
          >
            Blog
          </Button>
        </Link>
        <Link
          to={PAGE_ROUTES.GALLERY}
          className={"menu-top-button"}
        >
          <Button
            variant={"text"}
            color={"primary"}
            style={styles.menuButton}
          >
            Gallery
          </Button>
        </Link>
        <Link
          to={PAGE_ROUTES.CONTACT}
          className={"menu-top-button"}
        >
          <Button
            variant={"text"}
            color={"primary"}
            style={styles.menuButton}
          >
            Contact
          </Button>
        </Link>
        <a
          href={"https://shepstonegardens.our-venue.com"}
          target={"_blank"}
          rel={"noreferrer"}
          className={"menu-top-button featured"}
        >
          <Button
            variant={"contained"}
            color={"primary"}
            style={{
              ...styles.menuButton,
              ...styles.menuFeaturedButton,
            }}
          >
            Get a Quote
          </Button>
        </a>
      </div>

      {/* =================== MOBILE MENU =================== */}
      <div className={"mobile-menu-toggle"}>
        <IconButton onClick={openDrawer}>
          <MenuIcon />
        </IconButton>
      </div>

      <Drawer
        anchor={"top"}
        open={showDrawer}
        onClose={closeDrawer}
      >
        <div className={"drawer-inner"}>
          <h2 className={"drawer-menu-top-logo"}>THE SHEPSTONE EXPERIENCE</h2>
          <IconButton
            onClick={closeDrawer} 
            style={styles.drawerClose}
            size={"small"}
          >
            <Close />
          </IconButton>
          <br/><br/>
          <Link to={PAGE_ROUTES.HOME}>
            <Button
              variant={"text"}
              style={styles.drawerButton}
              onClick={closeDrawer}
            >
              Home
            </Button>
          </Link>
          <Link to={PAGE_ROUTES.ABOUT}>
            <Button
              variant={"text"}
              style={styles.drawerButton}
              onClick={closeDrawer}
            >
              About
            </Button>
          </Link>
          <Button
            variant={"text"}
            style={styles.drawerButton}
            onClick={toggleMobileSub}
            endIcon={(mobileSub) ? <ArrowDropUp /> : <ArrowDropDown />}
          >
            Christmas Market
          </Button>
          <Collapse in={mobileSub}>
            <Link to={PAGE_ROUTES.MARKET_TICKETS}>
              <Button
                variant={"text"}
                onClick={closeDrawer}
                style={styles.drawerSubButton}
              >
                Tickets
              </Button>
            </Link>
          
            <Link to={PAGE_ROUTES.MARKET_VENDOR}>
              <Button
                variant={"text"}
                onClick={closeDrawer}
                style={styles.drawerSubButton}
              >
                Become a vendor
              </Button>
            </Link>
          </Collapse>
          <Button
            variant={"text"}
            style={styles.drawerButton}
            onClick={() => setShowMobileDropdown(!showMobileDropdown)}
            endIcon={(showMobileDropdown) ? <ArrowDropUp /> : <ArrowDropDown />}
          >
            Venues
          </Button>
          <Collapse in={showMobileDropdown}>
            <div className={"drawer-venues-dropdown"}>
              <div className={"nav-venue-item"}>
                <h3>Shepstone Gardens</h3>
                <img src={"/images/venue-picker-shepstone.jpg"} />
                <Link to={PAGE_ROUTES.VENUES_SHEP}>
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={closeDrawer}
                  >
                    View Venue
                  </Button>
                </Link>
              </div>
              <div className={"nav-venue-item"}>
                <h3>The Great Hall</h3>
                <img src={"/images/venue-picker-great-hall.jpg"} />
                <Link to={PAGE_ROUTES.VENUES_HALL}>
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={closeDrawer}
                  >
                    View Venue
                  </Button>
                </Link>
              </div>
            </div>
          </Collapse>
          <Link to={PAGE_ROUTES.FLORALS}>
            <Button
              variant={"text"}
              style={styles.drawerButton}
              onClick={closeDrawer}
            >
              Florals
            </Button>
          </Link>
          <Link to={PAGE_ROUTES.BLOG}>
            <Button
              variant={"text"}
              style={styles.drawerButton}
              onClick={closeDrawer}
            >
              Blog
            </Button>
          </Link>
          <Link to={PAGE_ROUTES.GALLERY}>
            <Button
              variant={"text"}
              style={styles.drawerButton}
              onClick={closeDrawer}
            >
              Gallery
            </Button>
          </Link>
          <Link to={PAGE_ROUTES.CONTACT}>
            <Button
              variant={"text"}
              style={styles.drawerButton}
              onClick={closeDrawer}
            >
              Contact
            </Button>
          </Link>
          <a
            href={"https://shepstonegardens.our-venue.com"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            <Button
              variant={"contained"}
              color={"secondary"}
              style={styles.drawerFeaturedButton}
              onClick={closeDrawer}
            >
              Get a Quote
            </Button>
          </a>
          <br/><br/><br/><br/>
        </div>
      </Drawer>
    </nav>
  );
};

export default TopNav;

